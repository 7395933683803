import { useEffect } from "react";

export const Installed = () => {
  const param = new URLSearchParams(document.location.search);

  let source = param.get("source");
  let creative_id = param.get("creative_id");
  let ad_campaign_id = param.get("ad_campaign_id");
  let offer = param.get("offer");
  let creo_id = param.get("creo_id");
  let external_id = param.get("external_id");
  let site_id = param.get("site_id");
  let cost = param.get("cost");
  let sub_id_1 = param.get("sub_id_1");
  let sub_id_2 = param.get("sub_id_2");
  let sub_id_3 = param.get("sub_id_3");
  let sub_id_4 = param.get("sub_id_4");

  let id = param.get("id");
  let feed_id = param.get("feed_id");
  let pixel = param.get("pixel");
  let fbclid = param.get("fbclid");
  let access_token = param.get("access_token");

  if (!pixel) {
    pixel = localStorage.getItem("pixel");
  }

  if (!fbclid) {
    fbclid = localStorage.getItem("fbclid");
  }

  if (!access_token) {
    access_token = localStorage.getItem("access_token");
  }

  if (!id) {
    id = localStorage.getItem("id");
  }

  if (!feed_id) {
    feed_id = localStorage.getItem("feed_id");
  }

  if (!sub_id_1) {
    sub_id_1 = localStorage.getItem("sub_id_1");
  }

  if (!sub_id_2) {
    sub_id_2 = localStorage.getItem("sub_id_2");
  }

  if (!sub_id_3) {
    sub_id_3 = localStorage.getItem("sub_id_3");
  }

  if (!sub_id_4) {
    sub_id_4 = localStorage.getItem("sub_id_4");
  }

  if (!cost) {
    cost = localStorage.getItem("cost");
  }

  if (!creo_id) {
    creo_id = localStorage.getItem("creo_id");
  }

  if (!external_id) {
    external_id = localStorage.getItem("external_id");
  }

  if (!site_id) {
    site_id = localStorage.getItem("site_id");
  }

  if (!source) {
    source = localStorage.getItem("source");
  }

  if (!creative_id) {
    creative_id = localStorage.getItem("creative_id");
  }

  if (!ad_campaign_id) {
    ad_campaign_id = localStorage.getItem("ad_campaign_id");
  }

  if (!offer) {
    offer = localStorage.getItem("offer");
  }

  setTimeout(() => {
    document.getElementById("openApp").click();
  }, 17400);

  useEffect(() => {
    localStorage.removeItem("isClickedInstall");
    setTimeout(() => {
      if (id === "1606") {
        window.location.href =
          `https://arumo.top/JZ98t4s7?a=b` +
          (creo_id !== null ? `&creo_id=${creo_id}` : "") +
          (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
          (site_id !== null ? `&site_id=${site_id}` : "");
      } else if (id === "1605") {
        window.location.href =
          `https://arumo.top/8BDRrxHM?a=b` +
          (creative_id !== null ? `&creative_id=${creative_id}` : "") +
          (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
          (source !== null ? `&source=${source}` : "");
      } else if (id === "1608") {
        window.location.href =
          `https://arumo.top/GkYFtYQz?a=b` +
          (creative_id !== null ? `&creative_id=${creative_id}` : "") +
          (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
          (source !== null ? `&source=${source}` : "");
      } else if (id === "1607") {
        window.location.href =
          `https://arumo.top/P8nCqtwG?a=b` +
          (creative_id !== null ? `&creative_id=${creative_id}` : "") +
          (source !== null ? `&source=${source}` : "");
      } else if (id === "1609") {
        window.location.href =
          `https://arumo.top/56jkxN8z?a=b` +
          (creative_id !== null ? `&creative_id=${creative_id}` : "") +
          (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
          (source !== null ? `&source=${source}` : "");
      } else {
        window.location.href =
          `https://arumo.top/JZ98t4s7?a=b` +
          (creo_id !== null ? `&creo_id=${creo_id}` : "") +
          (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
          (site_id !== null ? `&site_id=${site_id}` : "");
      }
    }, 800);
  }, []);

  return (
    <>
      <div class="loader"></div>
      <div id="pixelTagId"></div>
    </>
  );
};
